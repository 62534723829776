<template>
	<div class="ContentMain">
		<div class="user pageContent">
			<div class="top">
				<h3>课程反馈</h3>
				<p @click="returnCourse">
					<span class="icon iconfont icon-papa-return"></span>返回课程
				</p>
			</div>
			<div class="content" v-if="category == 2">
				<el-row v-if="toefl.general">
					<el-col :span="12">
						<div class="grid-content bg-purple">
							<div class="title">
								<p>
									托福陪练<span>{{ appointmentTime }}</span>
								</p>
							</div>
							<h5>根据托福官方4项评分规则评定：</h5>
							<div class="main">
								<p>1、General Description</p>
								<div>
									<span>独立</span
									><b>{{
										toeflFeedbacks &&
											toeflFeedbacks.criteria[0].score[0]
												.value
									}}</b>
								</div>
								<div>
									<span>综合</span
									><b>{{
										toeflFeedbacks &&
											toeflFeedbacks.criteria[0].score[1]
												.value
									}}</b>
								</div>
							</div>
							<el-input
								type="textarea"
								v-model="toefl.general"
							></el-input>

							<div class="main">
								<p>2、Delivery</p>
								<div>
									<span>独立</span
									><b>{{
										toeflFeedbacks &&
											toeflFeedbacks.criteria[1].score[0]
												.value
									}}</b>
								</div>
								<div>
									<span>综合</span
									><b>{{
										toeflFeedbacks &&
											toeflFeedbacks.criteria[1].score[1]
												.value
									}}</b>
								</div>
							</div>
							<el-input
								type="textarea"
								v-model="toefl.delivery"
							></el-input>
							<div class="main">
								<p>3、Language Use</p>
								<div>
									<span>独立</span
									><b>{{
										toeflFeedbacks &&
											toeflFeedbacks.criteria[2].score[0]
												.value
									}}</b>
								</div>
								<div>
									<span>综合</span
									><b>{{
										toeflFeedbacks &&
											toeflFeedbacks.criteria[2].score[1]
												.value
									}}</b>
								</div>
							</div>
							<el-input
								type="textarea"
								v-model="toefl.language"
							></el-input>
							<div class="main">
								<p>4、Topic Development</p>
								<div>
									<span>独立</span
									><b>{{
										toeflFeedbacks &&
											toeflFeedbacks.criteria[3].score[0]
												.value
									}}</b>
								</div>
								<div>
									<span>综合</span
									><b>{{
										toeflFeedbacks &&
											toeflFeedbacks.criteria[3].score[1]
												.value
									}}</b>
								</div>
							</div>
							<el-input
								type="textarea"
								v-model="toefl.topic"
							></el-input>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="grid-content bg-purple-light">
							<div class="score">
								<p>
									口语分数<span>{{
										toeflFeedbacks && toeflFeedbacks.score
									}}</span>
								</p>
							</div>
							<h5>建议</h5>
							<el-input
								type="textarea"
								v-model="toefl.advice"
								:autosize="{ minRows: 5, maxRows: 7 }"
							></el-input>
							<h5>错误指正</h5>
							<el-input
								type="textarea"
								v-model="toefl.error_correction"
								:autosize="{ minRows: 5, maxRows: 7 }"
							></el-input>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="content" v-else>
				<el-row>
					<el-col :span="12">
						<div class="grid-content bg-purple">
							<div class="title">
								<p>
									雅思陪练<span>{{ appointmentTime }}</span>
								</p>
							</div>
							<h5>根据雅思官方4项评分规则评定：</h5>
							<div class="main">
								<p>1、Fluency and coherence</p>
								<div>
									<span>分数</span
									><b>{{
										ieltsFeedbacks &&
											ieltsFeedbacks.criteria[0].score[0]
												.value
									}}</b>
								</div>
							</div>
							<el-input
								type="textarea"
								v-model="ielts.fluency"
							></el-input>

							<div class="main">
								<p>2、Lexical Resource</p>
								<div>
									<span>分数</span
									><b>{{
										ieltsFeedbacks &&
											ieltsFeedbacks.criteria[1].score[0]
												.value
									}}</b>
								</div>
							</div>
							<el-input
								type="textarea"
								v-model="ielts.lexical"
							></el-input>
							<div class="main">
								<p>3、Grammar range and accuracy</p>
								<div>
									<span>分数</span
									><b>{{
										ieltsFeedbacks &&
											ieltsFeedbacks.criteria[2].score[0]
												.value
									}}</b>
								</div>
							</div>
							<el-input
								type="textarea"
								v-model="ielts.grammar"
							></el-input>
							<div class="main">
								<p>4、Pronunciation</p>
								<div>
									<span>分数</span
									><b>{{
										ieltsFeedbacks &&
											ieltsFeedbacks.criteria[3].score[0]
												.value
									}}</b>
								</div>
							</div>
							<el-input
								type="textarea"
								v-model="ielts.pronunciation"
							></el-input>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="grid-content bg-purple-light">
							<div class="score">
								<p>
									口语分数<span>{{
										ieltsFeedbacks && ieltsFeedbacks.score
									}}</span>
								</p>
							</div>
							<h5>建议</h5>
							<el-input
								type="textarea"
								v-model="ielts.advice"
								:autosize="{ minRows: 5, maxRows: 7 }"
							></el-input>
							<h5>错误指正</h5>
							<el-input
								type="textarea"
								v-model="ielts.error_correction"
								:autosize="{ minRows: 5, maxRows: 7 }"
							></el-input>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
import TrainingFeedback from './TrainingFeedback.js';

export default TrainingFeedback;
</script>
<style lang="less" scoped>
/*@import '../../assets/style/lib-base';*/
#app {
	text-align: left !important;
}
.ContentMain {
	background-color: #fafafa;
	height: 100vh;
	.user {
		background: #fff;
		min-height: 630px;
		width: 100%;
		border-radius: 5px;
		color: #999;
		display: flex;
		flex-direction: column;
		& > .bar {
			width: 100%;
			height: 30px;
			background-color: #e6e6e6;
			display: flex;
			justify-content: left;
			align-items: center;
			span {
				margin-left: 20px;
				cursor: pointer;
				i {
					font-size: 12px;
					color: #999;
				}
			}
		}
		.top {
			display: flex;
			height: 52px;
			justify-content: space-between;
			h3 {
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				margin-left: 30px;
			}
			p {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #666666;
				margin-right: 30px;
				cursor: pointer;
			}
		}
		.content {
			margin: 0 30px;
			.bg-purple {
				.title {
					height: 48px;
					background: #4d4d4d;
					border-radius: 4px;
					text-align: left;
					line-height: 48px;
					p {
						margin-left: 19px;
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: bold;
						color: #ffffff;
						span {
							margin-left: 20px;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #ffffff;
						}
					}
				}
				h5 {
					text-align: left;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #666666;
					margin: 0;
					margin-top: 20px;
				}
				.main {
					display: flex;
					justify-content: flex-start;
					p {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #666666;
					}
					div {
						display: flex;
						align-items: center;
						margin-left: 20px;
						span {
							display: block;
							width: 32px;
							height: 18px;
							background: #29d087;
							border-radius: 4px;
							font-size: 12px;
							line-height: 18px;
							font-weight: 500;
							color: #ffffff;
						}
						b {
							font-weight: normal;
							margin-left: 10px;
						}
					}
				}
				.el-textarea {
					width: 500px;
					margin-left: -64px;
				}
			}
			.bg-purple-light {
				.score {
					height: 48px;
					background: #29d087;
					border-radius: 4px;
					text-align: left;
					line-height: 48px;
					position: relative;
					left: -5px;
					p {
						margin-left: 19px;
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: bold;
						color: #ffffff;
						span {
							margin-left: 20px;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #ffffff;
						}
					}
				}
				h5 {
					text-align: left;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #666666;
					margin: 0;
					margin-top: 20px;
					margin-bottom: 8px;
				}
				.el-textarea {
					width: 500px;
					margin-left: -64px;
				}
			}
		}
	}
}
</style>
