import * as Api from '@/api/trainingSpeaking/trainingSpeaking';
export default {
    name: 'trainingFeedback',
    data() {
        return {
            ruleForm: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: '',
            },
            appointmentTime: '',

            ielts: {
                fluency: '',
                lexical: '',
                grammar: '',
                pronunciation: '',
                advice: '',
                error_correction: '',
            },
            ieltsFeedbacks: {},
            toefl: {
                general: '',
                delivery: '',
                language: '',
                topic: '',
                advice: '',
                error_correction: '',
            },
            toeflFeedbacks: {},
        };
    },
    props: ['id','category'],
    mounted() {
        if (this.category == 1) {
            this.getIeltsFeedback();
        } else if (this.category == 2) {
            this.getToeflFeedback();
        }
        this.appointmentTime = localStorage.getItem('appointmentTime');
    },
    methods: {
        returnCourse() {
            this.$router.push({
                name: 'trainingSpeakingMain',
            });
        },
        getIeltsFeedback() {
            Api.trainingFeedBackApi(this.id, '', (res) => {
                this.ieltsFeedbacks = res;
                this.ielts.fluency = res.criteria[0].content;
                this.ielts.lexical = res.criteria[1].content;
                this.ielts.grammar = res.criteria[2].content;
                this.ielts.pronunciation = res.criteria[3].content;
                this.ielts.advice = res.advice;
                this.ielts.error_correction = res.error_correction;
            });
        },
        getToeflFeedback() {
            Api.trainingFeedBackApi(this.id, '', (res) => {
                this.toeflFeedbacks = res;
                this.toefl.general = res.criteria[0].content;
                this.toefl.delivery = res.criteria[1].content;
                this.toefl.language = res.criteria[2].content;
                this.toefl.topic = res.criteria[3].content;
                this.toefl.advice = res.advice;
                this.toefl.error_correction = res.error_correction;
            });
        },
        returnBack() {
            this.$router.back()
        },
    },
};
